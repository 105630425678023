// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-me-en-jsx": () => import("./../src/pages/about-me.en.jsx" /* webpackChunkName: "component---src-pages-about-me-en-jsx" */),
  "component---src-pages-contact-en-jsx": () => import("./../src/pages/contact.en.jsx" /* webpackChunkName: "component---src-pages-contact-en-jsx" */),
  "component---src-pages-contacte-ca-jsx": () => import("./../src/pages/contacte.ca.jsx" /* webpackChunkName: "component---src-pages-contacte-ca-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-cv-ca-jsx": () => import("./../src/pages/cv.ca.jsx" /* webpackChunkName: "component---src-pages-cv-ca-jsx" */),
  "component---src-pages-cv-en-jsx": () => import("./../src/pages/cv.en.jsx" /* webpackChunkName: "component---src-pages-cv-en-jsx" */),
  "component---src-pages-cv-jsx": () => import("./../src/pages/cv.jsx" /* webpackChunkName: "component---src-pages-cv-jsx" */),
  "component---src-pages-galeria-ca-jsx": () => import("./../src/pages/galeria.ca.jsx" /* webpackChunkName: "component---src-pages-galeria-ca-jsx" */),
  "component---src-pages-galeria-jsx": () => import("./../src/pages/galeria.jsx" /* webpackChunkName: "component---src-pages-galeria-jsx" */),
  "component---src-pages-index-ca-jsx": () => import("./../src/pages/index.ca.jsx" /* webpackChunkName: "component---src-pages-index-ca-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-photos-en-jsx": () => import("./../src/pages/photos.en.jsx" /* webpackChunkName: "component---src-pages-photos-en-jsx" */),
  "component---src-pages-poesia-ca-jsx": () => import("./../src/pages/poesia.ca.jsx" /* webpackChunkName: "component---src-pages-poesia-ca-jsx" */),
  "component---src-pages-poesia-jsx": () => import("./../src/pages/poesia.jsx" /* webpackChunkName: "component---src-pages-poesia-jsx" */),
  "component---src-pages-poetry-en-jsx": () => import("./../src/pages/poetry.en.jsx" /* webpackChunkName: "component---src-pages-poetry-en-jsx" */),
  "component---src-pages-sobre-mi-ca-jsx": () => import("./../src/pages/sobre-mi.ca.jsx" /* webpackChunkName: "component---src-pages-sobre-mi-ca-jsx" */),
  "component---src-pages-sobre-mi-jsx": () => import("./../src/pages/sobre-mi.jsx" /* webpackChunkName: "component---src-pages-sobre-mi-jsx" */),
  "component---src-pages-videos-ca-jsx": () => import("./../src/pages/videos.ca.jsx" /* webpackChunkName: "component---src-pages-videos-ca-jsx" */),
  "component---src-pages-videos-en-jsx": () => import("./../src/pages/videos.en.jsx" /* webpackChunkName: "component---src-pages-videos-en-jsx" */),
  "component---src-pages-videos-jsx": () => import("./../src/pages/videos.jsx" /* webpackChunkName: "component---src-pages-videos-jsx" */)
}

